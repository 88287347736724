import React, { useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { runInAction } from 'mobx'
import { StackScreenProps } from '@react-navigation/stack'
//import PdfReader from 'rn-pdf-reader-js'
import { RootNavigationParamList } from '../../navigation/RootNav'
import { View, TouchableOpacity, StyleSheet, Share, ActivityIndicator, Platform, Text, Button } from 'react-native'
import { AppEvents, AppStateStore } from '../../contexts/AppStateStore'
import { Document } from '../../api/schema/models/Document'
import Icons from '../../components/icons'
import { AppColors as COLORS } from '../../common/AppColors'
import { openExternalUrl } from '../../common/Util'
import { BlockButton } from '../../components/BlockButton'
import { getModalManager } from '../../contexts/ModalContext'
import { ReceivedForm, ReceivedFormField } from '../../api/schema/models/ReceivedForm'
import ErrorBag from '../../common/ErrorBag'
import { API } from '../../api/API'
import { handleErrorResponse, extractErrorMessage, utc } from '../../common/Util'
import WebView from 'react-native-webview'
import { BottomDrawer } from '../../components/BottomDrawer'
import { DefaultText } from '../../components/DefaultText'

export type FormViewerScreenParams = {
  //receivedForm: ReceivedForm
  signatureId: number
  studentId: number
}

export const FormViewerScreen = observer((props: StackScreenProps<RootNavigationParamList, 'FormViewer'>) => {
  const { t } = useTranslation()
  //const receivedForm = props.route.params.receivedForm
  const signatureId = props.route.params.signatureId
  const studentId = props.route.params.studentId

  const state = useLocalObservable(() => ({
    document: undefined as Document | undefined,
    error: undefined as string | undefined,
    showInfo: false,
    isLoading: true,
    receivedForm: undefined as ReceivedForm | undefined,
  }))

  const loadForm = useCallback(async () => {
    runInAction(() => {
      state.error = undefined
      state.isLoading = true
    })

    try {
      const response = await API.getReceivedForm(signatureId, studentId)
      runInAction(() => {
        state.receivedForm = response.receivedForm
        state.isLoading = false
      })
      props.navigation.setOptions({
        title: state.receivedForm?.formTitle ? state.receivedForm.formTitle.slice(0, 25) + (state.receivedForm.formTitle.length > 25 ? '...' : '') : 'Document',
      })
    } catch (err: any) {
      runInAction(() => {
        state.error = extractErrorMessage(err.response)
        state.isLoading = false
      })
    }
  }, [state, signatureId, studentId])

  useEffect(() => {
    loadForm()
  }, [loadForm])


  React.useLayoutEffect(() => {
    props.navigation.setOptions({
      title: state.receivedForm?.formTitle ? state.receivedForm.formTitle.slice(0, 25) + (state.receivedForm.formTitle.length > 25 ? '...' : '') : 'Document',
      headerRight: () => <View style={{ flexDirection: 'row' }}>
        <TouchableOpacity
          onPress={() => showInfo()}
          style={styles.headerButton}
        >
          <Icons.Info size={24} color={COLORS.orange.primary} />
        </TouchableOpacity>
      </View>
    })
  }, [props.navigation])

  const submit = async (receivedForm: ReceivedForm, signed: boolean) => {
    if (receivedForm.isSignatureRequired && !signed) {
      getModalManager()
        .showModal({
          //title: t('Confirm', 'Confirm'),
          message: t('Electronic signature required', 'Are you sure you want to sign this Document?'),
          buttons: [
            {
              text: t('Cancel', 'Cancel'),
              variant: 'primary',
            },
            {
              text: t('Sign Document', 'Yes'),
              variant: 'secondary',
              onPress: dismiss => {
                dismiss()
                submit(receivedForm, true).then()
              },
            },

          ]
        })
    } else {
      runInAction(() => {
        state.error = undefined
      })

      AppStateStore.showActivityIndicator()

      try {

        if (!state.receivedForm) return

        await API.submitForm(
          state.receivedForm?.id!,
          {
            student_id: state.receivedForm.student.id,
            e_signature_group_id: state.receivedForm?.e_signature_group_id
          })

        AppStateStore.eventBus.emit(AppEvents.FormSigned)

        props.navigation.pop()
      } catch (err: any) {
        if (Platform.OS === 'web') {
          //Sentry.Browser.captureException(err)
        }
        else {
          //Sentry.Native.captureException(err)
        }
        runInAction(() => {
          runInAction(() => state.error = t('There was an error submitting the form', 'There was an error submitting the form'))

        })
      }

      AppStateStore.hideActivityIndicator()
    }
  }

  const showInfo = () => {
    runInAction(() => { state.showInfo = true })

  }

  /*   function unsupportedFile() {
      return (
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Icons.Alert height={20} width={20} variant='fill' color={COLORS.red.secondary} />
            <DefaultText bold style={{ marginLeft: 5 }}>Unsupported file type</DefaultText>
          </View>
          <TouchableOpacity
            onPress={() => openExternalUrl(state.receivedForm?.formUrl!)}
            style={styles.headerButton}
          >
            <Icons.Download size={24} color={COLORS.orange.primary} />
          </TouchableOpacity>
        </View>
      )
    } */

  function LoadingIndicatorView() {
    return <ActivityIndicator style={styles.spinner} size="large" />
  }


  return (
    <View style={styles.container}>
      {
        state.receivedForm?.formUrl ?
          <>
            <View style={{ flex: 1, backgroundColor: '#fff' }}>
              {
                Platform.OS === 'android' ?
                  <View style={{ flex: 1, backgroundColor: '#fff' }}>
                    <WebView
                      style={{ flex: 1, backgroundColor: '#fff' }}
                      source={{ uri: `https://docs.google.com/gview?embedded=true&url=${encodeURIComponent(state.receivedForm?.formUrl!)}` }}
                      onLoadEnd={() => runInAction(() => state.isLoading = false)}
                      //onLoad={() => setWebViewKey((prevKey) => prevKey + 1)}
                      onError={() => console.log('onError')}
                      renderLoading={LoadingIndicatorView}
                      startInLoadingState={true}
                      javaScriptEnabled={true}
                      originWhitelist={['*']}
                    />

                  </View>
                  :
                  <WebView
                    style={{ flex: 1, backgroundColor: '#fff' }}
                    source={{ uri: state.receivedForm?.formUrl! }}
                    onLoadEnd={() => runInAction(() => state.isLoading = false)}
                    onLoad={() => console.log('onLoad')}
                    onError={() => console.log('onError')}
                  />
              }
            </View>
            {
              !state.isLoading ?
                <View style={styles.footer}>
                  {
                    !state.receivedForm?.signedAt ?
                      <BlockButton
                        variant='secondary'
                        title={t('Sign', 'Sign')}
                        onPress={() => state.receivedForm &&
                          submit(state.receivedForm, false).then()}
                        style={styles.footerButton}
                      />
                      :
                      <View style={styles.footerSignedButton}>
                        <DefaultText style={{ color: '#fff', fontSize: 16, fontWeight: 'bold', lineHeight: 24 }} numberOfLines={2}>
                          {`Digital Signature: ${state.receivedForm?.signature}`}
                        </DefaultText>
                        <DefaultText style={{ color: '#fff', fontSize: 16, fontWeight: 'bold' }} numberOfLines={2}>
                          {`Signed on: ${state.receivedForm?.signedAt ? utc(state.receivedForm?.signedAt).format('MMM DD, YYYY') : ''}`}
                        </DefaultText>
                      </View>
                  }
                </View>
                : null

            }
          </>
          : null

      }


      {
        state.showInfo ?
          <BottomDrawer
            height={'65%'}
            radius={30}
            backgrounColor={'#fff'}
            outsideTouch={() => runInAction(() => state.showInfo = false)}
          >
            <View style={{ flex: 1, flexDirection: 'column', justifyContent: 'center' }}>
              <Text style={[styles.drawerHeaderText]}>{t('Information', 'Information')}</Text>
              <View style={{ flexDirection: 'column', alignItems: 'center', marginTop: 16, marginBottom: 32 }}>
                <DefaultText bold style={styles.subText} numberOfLines={1}> {state.receivedForm?.formTitle}</DefaultText>
                <DefaultText style={styles.subText} numberOfLines={2}>
                  <DefaultText style={styles.subText}>
                    Sent <DefaultText bold>{utc(state.receivedForm?.sentAt).format('MMM DD, YYYY')}</DefaultText> by <DefaultText bold>{state.receivedForm?.teacher?.displayName}</DefaultText>
                  </DefaultText>
                </DefaultText>
                <DefaultText style={styles.subText} numberOfLines={2}>
                  {t('For', 'For')} <DefaultText bold>{state.receivedForm?.student.firstName} {state.receivedForm?.student.lastName}</DefaultText>
                </DefaultText>
                <DefaultText style={[styles.subText, { marginTop: 16 }]} numberOfLines={10}>
                  {
                    `"${state.receivedForm?.formMessage}"`
                  }
                </DefaultText>
              </View>
              {/*  <DefaultText style={styles.subText}>{t('Review document fields', '\"Please review the attached document before completing the electronic signature.\"')}</DefaultText> */}
              <View style={styles.footerInfo}>
                <BlockButton
                  title={t('OK', 'OK')}
                  variant={'primary'}
                  onPress={() => runInAction(() => state.showInfo = false)}
                  textStyle={{ fontSize: 16 }}
                  style={styles.footerButton}
                />
              </View>
            </View>
          </BottomDrawer>
          : null

      }
    </View>
  )
})

const styles = StyleSheet.create({
  headerButton: {
    padding: 10,
  },
  container: {
    flex: 1,
    backgroundColor: COLORS.white,
  },
  footer: {
    paddingHorizontal: 16,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: 16,
    paddingBottom: 16,
  },
  footerInfo: {
    paddingHorizontal: 24,
    marginBottom: 24,
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
  },
  footerButton: {
    flex: 1,
    width: '100%',
    maxHeight: 60,
    borderRadius: 8,
    backgroundColor: COLORS.orange.primary,
  },
  footerSignedButton: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 24,
    width: '100%',
    height: 80,
    //maxHeight: 80,
    borderRadius: 8,
    backgroundColor: COLORS.leaf.secondary,
  },
  drawerHeaderText: {
    paddingTop: 30,
    fontSize: 24,
    color: COLORS.blue.primary,
    textAlign: 'center',
    fontWeight: '500',
  },
  subText: {
    paddingTop: 4,
    paddingHorizontal: 40,
    fontSize: 16,
    color: '#303030',
    textAlign: 'center',
    fontWeight: '400',
  },
  spinner: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0
  },
})