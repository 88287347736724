import { observer, useLocalObservable } from 'mobx-react-lite'
import { extractErrorMessage, fullName, getMimeType, relativeTime, getExtension, openExternalUrl } from '../../common/Util'
import { ActivityIndicator, FlatList, Image, KeyboardAvoidingView, ListRenderItemInfo, Platform, Pressable, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { AppEvents, AppStateStore } from '../../contexts/AppStateStore'
import { extendObservable, runInAction } from 'mobx'
import React, { useCallback, useEffect, useLayoutEffect, useRef } from 'react'
import { API } from '../../api/API'
import { Contact } from '../../api/schema/models/Contact'
import { Conversation } from '../../api/schema/models/Conversation'
import { DefaultText } from '../../components/DefaultText'
import { Message } from '../../api/schema/models/Message'
import { VideoPreview } from '../../components/VideoPreview'
import { SendMessageInput } from '../../components/SendMessageInput'
import { BlockButton } from '../../components/BlockButton'
import { useTranslation } from 'react-i18next'
import { ContentActivityIndicator } from '../../components/ContentActivityIndicator'
import { ErrorMessage } from '../../components/ErrorMessage'
import { getModalManager } from '../../contexts/ModalContext'
import { MessageAttachment } from '../../api/schema/models/MessageAttachment'
import { useFocusEffect } from '@react-navigation/native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { StackScreenProps } from '@react-navigation/stack'
import { useHeaderHeight } from '@react-navigation/elements'
import { ListRefreshControl } from '../../components/web-list-refresh-control/ListRefreshControl'
import { RootNavigationParamList } from '../../navigation/RootNav'
import { Attachment } from '../../components/AttachmentBar'
import { FileData } from '../../api/methods/upload-file'
import { FilePreview } from '../../components/messages/FilePreview'
import { KonektiIcon } from '../../components/KonektiIcon'
import { AppColors } from '../../common/AppColors'
import { ProfileImage } from '../../components/ProfileImage'
import { MessageAudioPlayer } from '../../components/messages/MessageAudioPlayer'
import { MessageAudio } from '../../components/messages/MessageAudio'
import Icons from '../../components/icons'
import { DefaultTextAutoLink } from '../../components/DefaultTextAutoLink'
//import * as Sentry from 'sentry-expo'

export type MessagesScreenParams = {
  conversationId: number
}

type MessageTranslation = {
  translatedText: string | null
  showTranslation: boolean
  loading: boolean
  loaded: boolean
}

export const MessagesScreen = observer((props: StackScreenProps<RootNavigationParamList, 'Messages'>) => {
  const { t } = useTranslation();

  const messagesRef = useRef<FlatList>(null);

  useEffect(() => {
    console.log(messagesRef.current?.scrollToEnd());
  }, [messagesRef])


  const state = useLocalObservable(() => ({
    message: '',
    loading: false,
    refreshing: false,
    error: undefined as string | undefined,
    submitting: false,
    get isValid() {
      return this.message.trim().length > 0 || this.attachment !== undefined
    },
    translations: {} as { [key: number]: MessageTranslation },
    attachment: undefined as Attachment | undefined,
    conversation: undefined as Conversation | undefined,
    messages: [] as Message[],
    contacts: [] as Contact[],
    get participants(): Contact[] {
      return this.conversation
        ? this.conversation.participantUserIds.map(p => this.contacts.find(c => c.userId === p)).filter(c => !!c) as Contact[]
        : []
    },
    get otherParticipants(): Contact[] {
      return this.participants.filter(p => p.userId !== AppStateStore.userContext?.id)
    },
  }))

  useFocusEffect(() => {
    const listener = AppStateStore.addPushNotificationListener(async notification => {
      if (
        (
          notification.request.content.data.notificationType === 'new-message'
          || notification.request.content.data.notificationType === 'message-processed'
        ) && notification.request.content.data.conversationId === props.route.params.conversationId
      ) {
        loadConversation().then()
      }

      return true
    })

    return () => listener.remove()
  })

  const onMessageCreated = () => {
    loadConversation().then()
  }

  const loadConversation = useCallback(async () => {
    runInAction(() => state.loading = true)

    try {
      const response = await API.getConversation(props.route.params.conversationId)
      API.getUnreadConversations().then(response => {
        AppStateStore.setUnreadMessagesCount(response.unread_messages)
      })

      runInAction(() => {
        state.conversation = response.conversation
        state.contacts = response.contacts
        state.messages = response.messages
      })
    } catch (err: any) {
      if (Platform.OS === 'web') {
        //Sentry.Browser.captureException(err)
      }
      else {
        //Sentry.Native.captureException(err)
      }
      runInAction(() => state.error = extractErrorMessage(err.response))
    }

    runInAction(() => state.loading = false)
  }, [props.route.params.conversationId])

  useEffect(() => {
    runInAction(() => {
      state.message = ''
      state.attachment = undefined
    })

    loadConversation().then()

    const listeners = [
      AppStateStore.eventBus.addRemovableListener(AppEvents.MessageCreated, onMessageCreated),
    ]

    return () => {
      listeners.forEach(l => l.remove())
    }
  }, [props.route.params.conversationId])

  const headerTitle = () => {
    if (state.otherParticipants.length === 1) {
      const [participant] = state.otherParticipants;
      return (
        <View style={styles.header}>
          <View style={{ marginRight: 12 }}>
            <ProfileImage url={participant?.profilePhotoUrl} size={40} />
          </View>
          <View>
            <Text style={styles.headerParticipantName}>{state.otherParticipants.map(p => fullName(p)).join(', ')}</Text>

          </View>
        </View>
      );
    }
    const participants = state.otherParticipants.map(p => fullName(p)).join(', ');

    const otherParticipants = ((participants).length > 30) ?
      (((participants).substring(0, 30 - 3)) + `... (${state.otherParticipants.length})`) :
      participants;

    if (state.otherParticipants.length > 1) {
      return (
        <View style={styles.header}>
          <View style={{ marginRight: 12 }}>
            <ProfileImage url={undefined} size={40} />
          </View>
          <View>
            <Text
              numberOfLines={1}
              style={styles.headerParticipantName}
              ellipsizeMode='head'
            >
              {otherParticipants}
            </Text>
            <Text
              style={styles.headerParticipantOnline}
            >{state.otherParticipants.length} recipient</Text>
          </View>
        </View>
      );
    }
  }

  useLayoutEffect(() => {
    props.navigation.setOptions({
      headerTitle,
    })
  }, [props.navigation, state.otherParticipants])

  const sendMessage = async (callback?: () => void) => {
    AppStateStore.showActivityIndicator()

    try {
      runInAction(() => {
        state.submitting = true
      })

      let attachment: MessageAttachment | undefined = undefined

      if (state.attachment) {
        const uploadData = await API.requestUpload()

        let mimeType = 'application/octet-stream'
        let filename = 'upload'
        if (state.attachment.type === 'file') {
          mimeType = state.attachment.mimeType
          filename = state.attachment.name
        } else if (!state.attachment.url.startsWith('data:')) {
          mimeType = getMimeType(state.attachment.url) || 'application/octet-stream'
        } else if (state.attachment.type === 'video' || state.attachment.type === 'photo') {
          mimeType = state.attachment.mimeType
        }

        let file: FileData = {
          uri: state.attachment.url,
          name: filename,
          type: mimeType,
        }
        if (state.attachment.url.startsWith('data:')) {
          const response = await fetch(state.attachment.url)
          const binary = await response.blob()
          file = new Blob([binary], {
            type: 'application/pdf',
          })
        }

        await API.uploadFile(
          uploadData.url,
          uploadData.fields,
          file,
        )

        attachment = {
          type: state.attachment.type,
          key: uploadData.key,
          filename: filename,
          mimeType: mimeType,
        }
      }

      await API.createMessage(props.route.params.conversationId, {
        message: state.message,
        attachment: attachment,
      })

      callback?.call(null);

      runInAction(() => {
        state.message = ''
        state.attachment = undefined
      })

      AppStateStore.eventBus.emit(AppEvents.MessageCreated)
    } catch (err: any) {
      if (Platform.OS === 'web') {
        //Sentry.Browser.captureException(err)
      }
      else {
        //Sentry.Native.captureException(err)
      }
      getModalManager()
        .showModal({
          title: t('Error', 'Error'),
          message: extractErrorMessage(err.response),
        })
    }

    AppStateStore.hideActivityIndicator()

    runInAction(() => {
      state.submitting = false
    })
  }

  const translateMessage = async (message: Message) => {
    if (!state.translations[message.id]) {
      runInAction(() => {
        extendObservable(state.translations, {
          [message.id]: {
            translatedText: null,
            loading: false,
            showTranslation: false,
            loaded: false,
          }
        })
      })
    }

    runInAction(() => {
      state.translations[message.id].loading = true
      state.translations[message.id].showTranslation = !state.translations[message.id].showTranslation
    })

    if (state.translations[message.id].showTranslation && !state.translations[message.id].loaded) {
      try {
        const translation = await API.getTranslatedMessage(message.id, AppStateStore.userLanguage)

        runInAction(() => {
          state.translations[message.id].translatedText = translation
          state.translations[message.id].loaded = true
        })
      } catch (err) {
        if (Platform.OS === 'web') {
          //Sentry.Browser.captureException(err)
        }
        else {
          //Sentry.Native.captureException(err)
        }
        console.log(err)
      }
    }

    runInAction(() => state.translations[message.id].loading = false)
  }

  const renderMessage = (message: Message, sentMessage: boolean) => {
    if (!message.message.length) {
      return null
    }

    const translation = state.translations[message.id]

    if (translation) {
      if (translation.loading) {
        return <ActivityIndicator />
      } else {
        if (translation.showTranslation && translation.translatedText) {
          return (
            <DefaultTextAutoLink style={[styles.messageText, sentMessage ? styles.sentMessageText : styles.receivedMessageText]}>
              {translation.translatedText}
            </DefaultTextAutoLink>
          );
        }
      }
    }

    return (
      <DefaultTextAutoLink style={[
        styles.messageText,
        sentMessage ?
          styles.sentMessageText :
          styles.receivedMessageText
      ]}
        color={sentMessage ? AppColors.white : AppColors.orange.primary}
      >
        {message.message}
      </DefaultTextAutoLink>
    );
  }

  type MessageItemProps = {
    message: Message
  }

  const renderModalImage = (uri: string | null): React.ReactElement => {
    if (!uri) return <></>;

    return (
      <>
        <Image
          style={{
            width: '100%',
            height: '100%',
            resizeMode: 'contain',
          }}
          source={{ uri }}
        />
      </>
    );
  }


  const renderAttachment = (message: Message, sentMessage: boolean) => {
    if (!message.processedAt) {
      return <DefaultText style={styles.messageProcessingText}>{t('Processing media attachments', 'Media attachments for this message are being processed and will be available shortly')}</DefaultText>
    } else if (message.attachments.videoUrl) {
      return <View style={styles.attachedVideoContainer}>
        <VideoPreview
          style={StyleSheet.absoluteFillObject}
          posterImageUrl={message.attachments.videoThumbnailUrl!}
          onPress={() => props.navigation.push('VideoPlayer', { uuid: message.attachments.videoUuid! })}
          resizeMode="cover"
        />
      </View>
    } else if (message.attachments.imageUrl) {
      return (
        <View style={styles.attachedImageContainer}>
          <TouchableOpacity onPress={() => {
            getModalManager()
              .showModal({
                type: 'content',
                showClosse: true,
                children: renderModalImage(message.attachments.imageUrl)
              })
          }}>
            <Image
              style={styles.attachedImage}
              resizeMode="cover"
              source={{ uri: message.attachments.imageUrl }}
            />
          </TouchableOpacity >
        </View >
      );
    } else if (message.attachments.audioUrl) {
      return (
        /*  <View style={styles.attachedAudioContainer}>
           <MessageAudioPlayer progressBarVariant="secondary" url={message.attachments.audioUrl} />
         </View> */
        <MessageAudio
          progressBarVariant="secondary"
          url={message.attachments.audioUrl}
          uuid={message.attachments.audioUuid!}
          sentMessage={sentMessage}
        />
      )
    } else if (message.attachments.fileUrl) {
      return <View style={styles.attachedFileContainer}>
        <FilePreview
          sentMessage={sentMessage}
          fileType={message.attachments.fileType!}
          fileName={message.attachments.fileName!}
          onPress={() => getFileAttachment(message.attachments.fileType!, message.attachments.fileUrl!, message.attachments.fileName!)}
        />
      </View>
    }

    return null
  }

  const getFileAttachment = (mimeType: string, fileUrl: string, fileName: string) => {
    const ext = getExtension(fileName)
    if (Platform.OS === 'web' || Platform.OS === 'android') {
      if (mimeType === 'application/pdf' || ext === 'pdf') {
        props.navigation.push('DocumentViewer', { url: fileUrl!, title: fileName! })
      } else {
        openExternalUrl(fileUrl!)
      }
    } else {
      if (mimeType === 'application/pdf' || ext === 'docx' || ext === 'doc' || ext === 'PNG' || ext === 'png' || ext === 'jpg') {
        props.navigation.push('DocumentViewer', { url: fileUrl!, title: fileName! })
      } else {
        openExternalUrl(fileUrl!)
      }

    }


  }



  const MessageItem = observer(({ message }: MessageItemProps) => {
    const participant = state.contacts.find(c => c.userId === message.userId)
    const sentMessage = participant!.userId === AppStateStore.userContext!.id
    const showParticipantName: boolean = state.contacts.length > 2;
    const flexMessage = !sentMessage && message.message.split(' ').length > 3 || message.attachments.audioUrl ? { flex: 1 } : {};
    const translation: MessageTranslation = state.translations[message.id] ?? { showTranslation: false };

    return (
      <View style={[styles.listItemContainer, Platform.select({ web: { transform: [{ scaleY: -1 }] } })]}>
        <DefaultText style={[styles.messageMeta, sentMessage ? styles.sentMessageMeta : styles.receivedMessageMeta]}>
          {relativeTime(message.createdAt)}
        </DefaultText>
        <View style={[styles.messageContainer, sentMessage ? styles.sentMessageContainer : styles.receivedMessageContainer]}>
          {
            !sentMessage && (
              <View style={styles.messageProfileImage}>
                <ProfileImage url={participant?.profilePhotoUrl} size={40} />
              </View>
            )
          }

          <View style={[
            styles.message,
            sentMessage ? styles.sentMessage : styles.receivedMessage,
            flexMessage
          ]}>
            {
              !sentMessage && showParticipantName && (
                <DefaultText style={styles.senderName}>
                  {participant ? fullName(participant) : '---'}
                </DefaultText>
              )
            }

            <View style={styles.receivedMessageWrapper}>
              {
                !sentMessage && (
                  <View>
                    {
                      message.message
                        ? <TouchableOpacity
                          style={styles.translateIconContainer}
                          onPress={() => translateMessage(message)}
                        >
                          <KonektiIcon
                            color={AppColors.orange.primary}
                            name={'translate-icon'}
                            size={20}
                            style={{ color: translation.showTranslation ? AppColors.orange.primary : AppColors.gray.neutral2 }}
                          />
                        </TouchableOpacity>
                        : null
                    }
                  </View>
                )
              }
              <View style={flexMessage}>
                {renderMessage(message, sentMessage)}
              </View>
            </View>
            {renderAttachment(message, sentMessage)}
          </View>
        </View>
      </View >
    );
  })

  const headerHeight = useHeaderHeight()

  const renderMessageListItem = useCallback((item: ListRenderItemInfo<Message>) => {

    return (
      <MessageItem message={item.item} />
    );
  }, [state.messages]);

  return (
    <View style={styles.container}>
      <KeyboardAvoidingView
        {...(Platform.OS === "ios" ? { behavior: "padding" } : {})}
        style={{ flex: 1 }}
        contentContainerStyle={{ flex: 1 }}
        keyboardVerticalOffset={(headerHeight ?? 0) + 10}
      >
        {
          (state.loading && !state.conversation)
            ? <ContentActivityIndicator />
            : state.error
              ? <>
                <ErrorMessage message={state.error} />
                <BlockButton
                  title={t('Retry', 'Retry')}
                  variant={'secondary'}
                  onPress={() => loadConversation()}
                />
              </>
              :
              <>
                <ListRefreshControl
                  onPress={() => loadConversation()}
                  refreshing={state.refreshing}
                />
                <FlatList
                  inverted
                  keyboardShouldPersistTaps="handled"
                  ref={messagesRef}
                  style={styles.list}
                  data={Platform.OS === 'web' ? state.messages.slice().reverse() : state.messages}
                  keyExtractor={item => String(item.id)}
                  renderItem={renderMessageListItem}
                  onRefresh={() => loadConversation()}
                  refreshing={state.refreshing}
                  onContentSizeChange={() => {
                    if (Platform.OS === 'web') {
                      messagesRef.current?.scrollToEnd({ animated: false })
                    }
                  }}
                />
                {
                  (!state.loading && !state.messages.length)
                    ? <View style={[styles.emptyList]}>
                      <DefaultText style={styles.emptyListText}>{t('You do not have any messages', 'You do not have any messages')}</DefaultText>
                      <BlockButton
                        variant="link"
                        title={t('Check Again', 'Check Again')}
                        onPress={() => loadConversation()}
                      />
                    </View>
                    : null
                }
                <View style={styles.footerContainer}>
                  <SafeAreaView edges={['bottom']}>
                    <View style={{ backgroundColor: '#fff' }}>
                      <SendMessageInput
                        attachment={state.attachment}
                        onAttached={(attachment) => runInAction(() => state.attachment = attachment)}
                        message={state.message}
                        onMessageChanged={message => runInAction(() => state.message = message)}
                        onSendPressed={sendMessage}
                        isValid={state.isValid}
                      />
                    </View>
                  </SafeAreaView>
                </View>
              </>
        }
      </KeyboardAvoidingView>
    </View >
  );
})

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: AppColors.white,
    paddingHorizontal: 12,
  },

  header: {
    flexDirection: 'row',
    alignItems: 'center',
    // overflow: 'hidden',
  },
  headerParticipantName: {

    fontFamily: 'Rubik_600SemiBold',
    color: AppColors.gray.neutral1,
    fontSize: 16,
  },
  headerParticipantOnline: {
    fontFamily: 'Rubik_400Regular',
    color: AppColors.gray.neutral2,
    fontSize: 14,
  },

  list: {
    flex: 1,
  },
  emptyList: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  emptyListText: {
    textAlign: 'center',
    fontSize: 16,
    color: '#666',
  },
  itemSeparator: {
    height: 1,
    backgroundColor: '#ddd',
  },
  listItemContainer: {
    padding: 10,
  },
  messageMeta: {
    fontSize: 14,
    color: AppColors.gray.neutral3,
    fontFamily: 'Rubik_400Regular',
    marginBottom: 18,
    marginTop: 8,
  },
  sentMessageMeta: {
    textAlign: 'center',
  },
  receivedMessageMeta: {
    textAlign: 'center',
  },
  messageProfileImage: {
    alignSelf: 'flex-end',
    marginRight: 9,
  },
  messageContainer: {
    flexDirection: 'row',
  },
  sentMessageContainer: {
    marginLeft: 40,
    justifyContent: 'flex-end',
  },
  receivedMessageContainer: {
    marginRight: 40,
    justifyContent: 'flex-start',
  },
  message: {
    padding: 10,
    borderRadius: 8,
    maxWidth: '90%',
  },
  translateIconContainer: {
    width: 35,
    height: 35,
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  sentMessage: {
    backgroundColor: AppColors.blue.primary,
  },
  receivedMessage: {
    backgroundColor: AppColors.gray.neutral5
  },
  receivedMessageWrapper: {
    flexDirection: 'row-reverse',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  messageText: {
    fontSize: 16,
  },
  sentMessageText: {
    color: '#fff',
    marginBottom: 6,
  },
  receivedMessageText: {
    color: AppColors.gray.neutral1,
  },
  senderName: {
    color: AppColors.blue.primary,
    fontWeight: '500',
    fontSize: 16,
    marginRight: 35,
  },
  footerContainer: {
    //backgroundColor: '#ddd',
    marginBottom: Platform.OS === 'ios' ? 0 : 16,
  },
  messageProcessingText: {
    color: AppColors.red.secondary,
    fontSize: 14,
    marginTop: 8,
    textShadowColor: '#666',
    textShadowRadius: 1,
  },

  attachedImageContainer: {
    width: 100,
    height: 100,
    marginTop: 10,
    borderRadius: 8,
    overflow: 'hidden',
  },
  attachedImage: {
    width: 100,
    height: 100,
  },
  attachedVideoContainer: {
    width: 100,
    height: 100,
    alignSelf: 'flex-end',
    marginTop: 10,
    borderRadius: 8,
    overflow: 'hidden',
  },
  attachedAudioContainer: {
    width: 250,
    //height: 20,
  },
  attachedFileContainer: {
    alignSelf: 'flex-end',
    //marginTop: 10,
  },
})

